<script setup>
import { defineAsyncComponent } from 'vue'

const kartustock = defineAsyncComponent(() =>
  import('@/components/laporan/pembelian/DaftarPembelian.vue')
)
</script>

<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Lihat Transaksi</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Inventory / Lihat Transaksi</span>
      </div>
    </div>
    <div class="grid">
      <div class="card col-12 md:col-12" style="height: 300px">
        <kartustock displayTitle="false" />
      </div>
    </div>
  </div>
</template>
